/* Product Box Helper */
export default class ProductBoxHelper {
  #validClasses = ['pb-image', 'pb-title', 'pb-desc', 'pb-price', 'pb-link'];

  constructor(component) {
    this.component = component;
  }

  getCssClass() {
    return this.component.getAttributes()['css-class'] || '';
  }

  getPBClasses() {
    return this.getCssClass()
      .split(' ')
      .filter((c) => c.startsWith('pb-'));
  }

  getDataClass() {
    return this.getPBClasses().find((c) => this.#validClasses.includes(c)) || [];
  }

  isDirty() {
    return this.getPBClasses().includes('pb-modified');
  }

  isPBAndNotDirty() {
    return this.getDataClass().length && !this.isDirty();
  }

  setIsDirty() {
    this.component.addAttributes({ 'css-class': `${this.getCssClass()} pb-modified` });
  }

  getComponentData() {
    let data;
    const $html = mQuery(this.component.toHTML());
    const pbClass = this.getDataClass();
    switch (pbClass) {
      case 'pb-image':
        data = $html.attr('src').trim();
        break;
      case 'pb-link':
        data = $html.attr('href').trim();
        break;
      case 'pb-title':
      case 'pb-desc':
      case 'pb-price':
        data = $html.text().trim();
        break;
      default:
        break;
    }
    return data ? { [pbClass.substr(3)]: data } : null;
  }
}
