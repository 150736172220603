import { BrandConfig, BrandOptions } from './helpers';

const compType = 'pb-button';
export default function addProductBoxButtonComponent(editor, blockManager, brand) {
  let changing = false;
  const dc = editor.DomComponents;
  const baseTypeName = 'mj-button';
  const tagName = 'mj-button';
  const baseType = dc.getType(baseTypeName);
  const baseModel = baseType.model;
  const defaultStyle = BrandConfig[brand]
    ? {
        ...BrandConfig[brand].button_styles,
      }
    : {};
  const pbButtonModel = {
    name: `ProductBox Button`,
    tagName,
    attributes: {
      'data-gjs-type': `${compType}`,
      'data-slot': `${compType}`,
      brand: '',
    },
    traits: [
      ...baseModel.prototype.defaults.traits,
      {
        label: 'Brand',
        type: 'select',
        name: 'brand',
        options: BrandOptions,
      },
    ],
    style: defaultStyle,
  };
  const model = {
    defaults: {
      ...baseModel.prototype.defaults,
      ...pbButtonModel,
    },
    init() {
      const attrs = { ...this.get('attributes') };
      const style = { ...this.get('style-default'), ...this.get('style') };

      for (const prop in style) {
        if (!(prop in attrs)) {
          attrs[prop] = style[prop];
        }
      }

      this.set('attributes', attrs);
      this.set('style', attrs);
      this.listenTo(this, 'change:style', this.handleStyleChange);
      this.listenTo(this, 'change:attributes', this.handleAttributeChange);
      this.listenTo(this, 'change:attributes:brand', this.handleBrandChange);
    },
    handleAttributeChange() {
      if (!changing) {
        changing = true;
        this.set('style', this.get('attributes'));
        changing = false;
      }
    },

    handleStyleChange() {
      if (!changing) {
        changing = true;
        this.set('attributes', this.get('style'));
        changing = false;
      }
    },

    handleBrandChange(e, newBrand) {
      if (!changing) {
        changing = true;
        const attributes = this.getAttributes();
        const brandStyles = BrandConfig[newBrand].button_styles;
        this.set('attributes', {
          ...attributes,
          ...brandStyles,
        });
        changing = false;
      }
    },
  };
  const view = baseType.view.extend({
    attributes: {
      style: 'pointer-events: all; display: table; width: 100%;user-select: none;',
    },
  });

  dc.addType(`${compType}`, {
    extend: baseTypeName,
    model,
    view,
    isComponent(el) {
      if (el.getAttribute && el.getAttribute('data-slot') === `${compType}`) {
        return {
          type: `${compType}`,
        };
      }
      return false;
    },
  });
}
