import { getNonSelectableText, getNonClosingImg, getImageDimensions } from './helpers';
import PBHelper from './helpers/product-box-helper';

const componentType = 'product-box';
const componentSlot = 'productBox';
const requestControllers = new Set();
let forcefulAbort = false;
function getProductBoxContent(lang, count = 3) {
  const content = ['de', 'fr'].includes(lang)
    ? `Enter a Product ID`
    : `Selected language(${lang}) should be German or French.`;
  const width = (100 / count).toFixed(0);
  const oneBlock = `<mj-column data-gjs-type="${componentType}" width="${width}%" padding-top="0" padding-bottom="5px" padding-right="5px" padding-left="5px">
      ${getNonSelectableText(content, ['padding-top="10px"'])}
</mj-column>`;

  const blocks = [];
  for (let i = 1; i <= count; i += 1) {
    blocks.push(oneBlock);
  }
  return blocks.join('');
}

function addProductBoxModalPopup(editor, lang) {
  editor.on('component:add', (component) => {
    if (
      component.get('type') === 'mj-section' &&
      component.getAttributes()['data-slot'] === 'mj-section-pb-container'
    ) {
      component.removeAttributes('data-slot');
      const modal = editor.Modal;
      modal.setTitle('Products Count');
      editor.Modal.setContent(`
      <div>
        <label>Select Number of Products - </label>
        <select id="product_box_count">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3" selected>3</option>
        </select>
      </div>
      `);
      modal.open();
      let count = 3;
      mQuery(document).on('change', '#product_box_count', (e) => {
        count = +e.target.value;
      });
      modal.onceClose(() => {
        component.replaceWith(`<mj-section>${getProductBoxContent(lang, count)}</mj-section>`);
        mQuery(document).off('change', '#product_box_count');
      });
    }
  });
}
function addProductBoxComponent(editor, brand, lang) {
  const dc = editor.DomComponents;
  dc.addType(componentType, {
    extend: 'mj-column',
    // extendFn: ['init'],
    model: {
      defaults: {
        name: 'Product Box',
        draggable: true,
        droppable: true,
        editable: false,
        removable: false,
        copyable: false,
        propagate: ['copyable'],
        traits: [
          {
            label: 'Product ID',
            type: 'text',
            name: 'product_id',
          },
        ],
        attributes: {
          'data-gjs-type': componentType,
          'data-slot': componentSlot,
          product_id: '',
        },
      },
      setModelProductDetail() {
        let productDetail = {};
        this.components().each((c) => {
          const pbHelper = new PBHelper(c);
          const data = pbHelper.getComponentData(c);
          if (data) {
            productDetail = { ...productDetail, ...data };
          }
        });
        this.set('productDetail', productDetail);
        console.log({ productDetail });
      },
      init() {
        this.setModelProductDetail();
        this.on('change:attributes:product_id', this.handleProductIDChange);
      },
      getProductDetail(productId, signal) {
        const pluginConfig = window.mauticPlugin?.GrapesJsProductBoxBundle || {};
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Basic ${pluginConfig.apiToken || ''}`);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
          credentials: 'include',
          signal,
        };

        const where = [
          `where[0][expr]=andX&where[0][val][0][col]=language&where[0][val][0][expr]=eq&where[0][val][0][val]=${lang}`,
          `&where[0][val][1][col]=productId&where[0][val][1][expr]=like&where[0][val][1][val]=${productId}%`,
        ];
        if (brand) {
          where.push(
            `&where[0][val][2][col]=clientId&where[0][val][2][expr]=eq&where[0][val][2][val]=${brand}`
          );
        }
        return fetch(`${pluginConfig.apiUrl || ''}?${where.join('')}`, requestOptions);
      },
      renderNonSelectableText(text, props = ['padding-top="10px"']) {
        this.components(getNonSelectableText(text, props));
      },
      async handleProductIDChange(e, productId) {
        if (!productId) {
          return this.renderNonSelectableText('Enter a Product ID');
        }
        const abortController = new AbortController();
        requestControllers.add(abortController);
        this.renderNonSelectableText('Loading product details...');
        let hasError = false;
        const response = await this.getProductDetail(productId, abortController.signal)
          .then((res) => res.json())
          .catch((error) => {
            console.log(error);
            this.renderNonSelectableText('Error occurred while loading product details!');
            hasError = true;
          })
          .finally(() => {
            requestControllers.delete(abortController);
            if (forcefulAbort) {
              this.renderNonSelectableText('Enter a Product ID');
              editor.runCommand('mautic-editor-email-mjml-close');
              forcefulAbort = false;
            }
          });
        if (hasError) {
          return null;
        }
        const { lehner_products: lehnerProducts, total, errors } = response;
        let productKey;
        if (errors && errors.length && errors[0].message) {
          return this.renderNonSelectableText(errors[0].message);
        }
        if (+total === 0) {
          return this.renderNonSelectableText('Product not found');
        }
        let rangeProducts = total;
        if (total > 1) {
          const priceMap = {};
          Object.entries(lehnerProducts).forEach(([prodId, { price, salePrice }]) => {
            const tmpPrice = +salePrice ? +salePrice : +price;
            priceMap[tmpPrice] = prodId;
          });
          const minPrice = Math.min(...Object.keys(priceMap));
          const maxPrice = Math.max(...Object.keys(priceMap));
          if (minPrice === maxPrice) {
            rangeProducts = 1;
          }
          productKey = priceMap[minPrice];
        } else {
          [productKey] = Object.keys(lehnerProducts);
        }
        const styles =
          'font-family="Lato, Arial, sans-serif" line-height="20px" align="center" padding-top="5px" padding-right="10px" padding-bottom="5px" padding-left="10px"';
        const {
          image,
          title,
          description,
          currency = 'CHF',
          price,
          salePrice,
          link = '#',
        } = lehnerProducts[productKey] || {};
        const tempLang = lang === 'fr' ? 'dès' : 'ab';
        const dispPrice = Number(salePrice || price).toFixed(2);
        let imageProps = [];
        const maxHeight = 300;
        try {
          const { width, height } = await getImageDimensions(image);
          let ratio = 1;
          if (height > maxHeight) {
            ratio = maxHeight / height;
          }
          imageProps = [`width="${parseInt(width * ratio, 10)}px"`];
        } catch (imageError) {
          console.log({ imageError });
        }
        this.components(`
          ${getNonClosingImg(image, title, [
            'css-class="pb-image"',
            `max-height="${maxHeight}px"`,
            `href="${link}"`,
            ...imageProps,
          ])}
          <mj-text css-class="pb-title" font-size="20px" ${styles} font-weight="900">${title}</mj-text>
          <mj-text css-class="pb-desc" font-size="14px" ${styles}>${description}</mj-text>
          <mj-text css-class="pb-price" font-size="18px" padding-top="20px" padding-bottom="5px" padding-right="5px" padding-left="5px" align="center">
          <p>${rangeProducts > 1 ? tempLang : ''} <strong>${currency} ${dispPrice}</strong> ${
          salePrice ? `<s>${Number(price).toFixed(2)}</s>` : ''
        }</p>
          </mj-text>
          <mj-button css-class="pb-link" href="${link}" data-gjs-type="pb-button" data-slot="pb-button" brand="${brand}" padding-top="0">
            ${lang === 'fr' ? 'Découvrir' : 'Jetzt entdecken'}
          </mj-button>
        `);
        this.addAttributes({ 'css-class': `product-box product-id-${productId}` });
        return this.setModelProductDetail();
      },
    },
    isComponent(el) {
      if (el.getAttribute && el.getAttribute('data-slot') === componentSlot) {
        return {
          type: componentType,
        };
      }
      return false;
    },
  });
}

export default function productBox(editor, blockManager, brand, lang) {
  editor.on('component:update', (component) => {
    const pbHelper = new PBHelper(component);
    if (pbHelper.isPBAndNotDirty()) {
      const propName = pbHelper.getDataClass().substr(3);
      const productDetail = component.parent().get('productDetail') || {};
      const componentData = pbHelper.getComponentData() || {};
      if (propName && productDetail[propName] !== componentData[propName]) {
        pbHelper.setIsDirty();
      }
    }
  });
  editor.on('component:update:content', (model) => {
    const parent = model.parent();
    if (parent && parent.get('type') === 'product-box') {
      const content = model.get('content');
      if (content.startsWith("<p>") && content.endsWith("</p>")) {
        const newContent = content.slice(3, -4);
        model.set({
          content: newContent,
        });
        console.log({content: newContent})
      }
    }
  });
  editor.on('run:mautic-editor-email-mjml-close:before', (options) => {
    if (requestControllers.size) {
      // eslint-disable-next-line no-param-reassign
      options.abort = true;
      forcefulAbort = true;
      requestControllers.forEach((requestController) => {
        requestController.abort();
      });
    }
  });
  editor.on('component:selected', (model) => {
    const parent = model.parent();
    if (parent && parent.get('type') === 'product-box') {
      model.set({
        draggable: `[data-gjs-type=product-box]#${parent.ccid}`,
      });
    }
  });
  addProductBoxModalPopup(editor, lang);
  addProductBoxComponent(editor, brand, lang);
  blockManager.add(componentType, {
    label: 'Product Box',
    category: Mautic.translate('mautic.plugin.grapesjs.customblocks.category'),
    attributes: { class: 'fa fa-th' },
    content: `
    <mj-section data-slot="mj-section-pb-container">
      <mj-column width="100%">${getNonSelectableText(
        'Select number of products and close the popup'
      )}</mj-column>
    </mj-section>
    `,
  });
}
