import GrapesJsPlugin from './src/index';

// create a global window-object which holds the information about GrapesJS plugins
if (!window.MauticGrapesJsPlugins) window.MauticGrapesJsPlugins = [];

// add the plugin-function with a name to the window-object
window.MauticGrapesJsPlugins.push({
  name: 'GrapesJsProductBoxPlugin',
  plugin: GrapesJsPlugin,
  context: ['email-mjml'],
});
