import GrapesJsBlocks from './blocks/index';

export default function GrapesJsPlugin(editor) {
  const customBlocks = new GrapesJsBlocks(editor);
  customBlocks.addBlocks();

  // Add default white background color to mj-section
  editor.on('component:add', (component) => {
    if (component.get('type') === 'mj-section') {
      const currentStyle = component.getStyle();
      if (!currentStyle['background-color']) {
        component.setStyle({ ...currentStyle, 'background-color': 'white' });
      }
    }
  });
}
