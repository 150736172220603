const commonStyles = {
  'font-size': '14px',
  'line-height': '20px',
  'border-radius': '17px',
  padding: '8px 24px',
  color: 'white',
  'font-family': 'Lato,Arial,sans-serif',
};

export const BrandConfig = {
  LV: {
    name: 'Lehner-Versand',
    button_styles: {
      ...commonStyles,
      'background-color': '#c24c99',
    },
  },
  WG: {
    name: 'Workgear',
    button_styles: {
      ...commonStyles,
      'background-color': '#f3a222',
    },
  },
  BW: {
    name: 'Bettwaesche',
    button_styles: {
      ...commonStyles,
      'background-color': '#733982',
    },
  },
};

export const getDefaultBrand = () => {
  const defaultBrand = {};
  const mjmlText = mQuery('textarea.builder-mjml').val();
  const text = mQuery(mjmlText).find('mj-title').text().trim();
  const [brand, language] = text.toLowerCase().split(' ');
  const found = Object.entries(BrandConfig).find(([id]) => {
    if (brand === id.toLowerCase()) {
      defaultBrand.brand = id;
    }
    return defaultBrand.brand;
  });
  if (['de', 'fr'].includes(language) === false) {
    console.warn(`Selected language(${language}) should be German or French.`);
  } else {
    defaultBrand.lang = language.toLowerCase();
  }
  if (!found) {
    console.warn('mj-title did not match to any known website', text);
  }
  return defaultBrand;
};

export const BrandOptions = Object.entries(BrandConfig).map(([id, { name }]) => ({ id, name }));

export const getNonSelectableText = (text, props = []) => {
  const newProps = [
    ...props,
    'padding-top="0"',
    'padding-bottom="5px"',
    'padding-right="5px"',
    'padding-left="5px"',
    'align="center"',
  ];
  return `<mj-text 
    data-gjs-type="non-selectable-text" 
    data-slot="non-selectable-text" 
    ${newProps.join(' ')}
  >${text}</mj-text>`;
};

export const getNonClosingImg = (src, title, props = []) => {
  const newProps = [
    ...props,
    'padding-top="10px"',
    'padding-bottom="5px"',
    'padding-right="10px"',
    'padding-left="10px"',
    'align="center"',
  ];
  return `<mj-image 
    data-gjs-type="non-closing-img" 
    data-slot="non-closing-img" 
    ${newProps.join(' ')}
    src="${src}"
    title="${title}"
    alt="${title}"
  ></mj-image>`;
};

// helper to get dimensions of an image
export const getImageDimensions = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = img;
      resolve({ width, height });
    };
    img.onerror = () => {
      reject(new Error('There was some problem with the image.'));
    };
    img.src = url;
  });
