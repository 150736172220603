import { getDefaultBrand } from './helpers';
import productBox from './product-box';
import productBoxButton from './pb-button';

export default class GrapesJsBlocks {
  blockManager;

  editor;

  constructor(editor) {
    this.editor = editor;
    this.blockManager = editor.BlockManager;

    editor.DomComponents.addType('non-selectable-text', {
      extend: 'mj-text',
      model: {
        defaults: {
          selectable: false,
          highlightable: false,
          hoverable: false,
          attributes: {
            'data-gjs-type': 'non-selectable-text',
            'data-slot': 'non-selectable-text',
          },
        },
      },
    });

    editor.DomComponents.addType('non-closing-img', {
      extend: 'mj-image',
      model: {
        defaults: {
          void: false,
          attributes: {
            'data-gjs-type': 'non-closing-img',
            'data-slot': 'non-closing-img',
          },
        },
      },
    });

    editor.DomComponents.addType('pb-button', {
      extend: 'mj-button',
      model: {
        defaults: {
          attributes: {
            'data-gjs-type': 'pb-button',
            'data-slot': 'pb-button',
            brand: '',
          },
        },
      },
    });
  }

  addBlocks() {
    const { brand, lang } = getDefaultBrand(this.editor);
    const blocks = [productBox, productBoxButton];
    blocks.forEach((block) => {
      block(this.editor, this.blockManager, brand, lang);
    });
  }
}
